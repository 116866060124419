import React, { useState } from "react";
import { css } from "@emotion/core";
import { DateTime } from "luxon";

import { createBlogListUrl, createBlogPostUrl, createBlogExcerpt } from "../utils/blog";
import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { BREAKPOINTS, COLORS } from "../css-vars";

import SmartLink from "./SmartLink";
import Paragraph from "./Paragraph";
import Heading from "./Heading";
import Strapline from "./Strapline";
import SmartImg from "./SmartImg";

const BlogItem = ({
  date,
  category,
  title,
  heading,
  headingTag = `h3`,
  headingLevel = `h4`,
  slug,
  coverImage,
  layout = `vertical-small`,
  content,
  ...props
}) => {

  if (!category && !title) title = `Blog`;

  const link = createBlogPostUrl(slug);

  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);

  const VARIANTS_MAP = {
    'vertical-small': {
      excerptLength: 0,
    },
    'vertical-large': {
      excerptLevel: `small`,
      excerptLength: 300,
    },
    'horizontal-small': {
      excerptLevel: `small`,
      excerptLength: 100,
    },
  };

  const variant = VARIANTS_MAP[layout];

  return (
    // WHY: When emotion changes the class of the heading the pseudo element is removed and redrawn which triggers
    //      onMouseLeave and then onMouseEnter again which causes a flicker and performance issue. This hack allows
    //      a traditional class to be applied which doesn't cause the above effects, and can be used to handle hover
    //      and focus states across the whole item (without clashing with the category URL)
    <article {...props} className={`${props.className || ``} ${(hovered || focused) ? `is--hovered` : ``}`} css={css`
      position: relative;

      ${layout === `horizontal-small` && `
        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          display: flex;
        }
      `}
    `}>
      {coverImage && <div css={css`
        align-self: flex-start;
        position: relative;

        ${layout !== `vertical-large` && `
          max-width: ${pixelsToRem(360)};

          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            max-width: ${pixelsToRem(460)};
          }
        `}

        ${layout === `horizontal-small` && `
          ${createSpacingUnitMediaQueries({
            'margin-right': `calc(SPACING_UNIT / 4)`,
            'width': `calc(50% - (SPACING_UNIT / 2))`,
          }, {
            excludeBreakpoints: [`tiny`],
            includeMin: {
              small: `flex-shrink: 0;`,
            },
          })}
        `}

        ::before {
          content: '';
          display: block;
          padding-top: ${9 / 16 * 100}%;
          position: relative;
          background: ${COLORS.primary};
          z-index: 1;
          opacity: 0;

          article.is--hovered > & {
            opacity: 0.2;
          }
        }

      `}>
        <SmartImg fluid={coverImage.localFile.childImageSharp.fluid} alt="" css={css`
          position: absolute !important; // WHY: Gatsby Image forces position: relative; inline
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}/>
      </div>}

      <div>
        <Strapline css={css`
          display: inline-block;
          vertical-align: top;
          margin-top: 2em;

          ${layout === `horizontal-small` && `
            ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
              margin-top: 0.5em;
            }
          `}

          ${link && `
            position: relative;
            z-index: 3;

            // WHY: Increases the hit-state of the link
            a {
              padding: 0.5em;
              margin: -0.5em;
            }
          `}
        `}>
          {title || <SmartLink link={createBlogListUrl(1, category)} title={`See all posts in ${category}`} css={css`
            :hover,
            :focus {
              text-decoration: underline;
            }
          `}>{category}</SmartLink>}
        </Strapline>

        {heading && <Heading tag={headingTag} level={headingLevel} spacingTop="none" spacingBottom="half" css={css`
          position: static;

          ${layout !== `vertical-large` && `
            max-width: 22em;
          `}
        `}>
          {link ?
            <SmartLink link={link} title="Read the post"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}

              css={css`
                article.is--hovered & {
                  text-decoration: underline;
                  color: ${COLORS.primaryA11y};
                }

                ::before {
                  content: '';
                  top: 0;
                  left: 0;
                  width: 100%;
                  position: absolute;
                  height: 100%;
                  z-index: 2;
                }
              `}
            >{heading}</SmartLink> :
            heading
          }
        </Heading>}

        {date && <Paragraph
          level={variant.excerptLevel}
          spacingBottom="none"
          spacingTop="none"
          css={css`
            color: ${COLORS.dark};
            display: block;
            pointer-events: none;

            ${layout !== `vertical-large` && `
              max-width: 32em;
            `}
          `}
        >
          <time dateTime={DateTime.fromISO(date).toISODate()} css={css`
            font-weight: 500;

            article.is--hovered & {
              color: ${COLORS.primaryA11y};
            }
          `}>{DateTime.fromISO(date).toFormat(`MMMM d, y`)}</time>
          {!!variant.excerptLength && ` – ${createBlogExcerpt(content, variant.excerptLength)}`}
        </Paragraph>}
      </div>
    </article>
  )
};

export default BlogItem
