import React from "react";
import { css } from "@emotion/core";
import { graphql } from "gatsby";

import { responsiveFontSizes, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { createBlogListUrl } from "../utils/blog";
import { DEFAULT_FONT_SIZES_MAP } from "../css-vars";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import ResponsiveContainer from "../components/ResponsiveContainer";
import BlogItem from "../components/BlogItem";
import SmartLink from "../components/SmartLink";
import Heading from "../components/Heading";
import Strapline from "../components/Strapline";

export const queryBlogPosts = graphql`
  query queryBlogPosts($skip: Int!, $limit: Int!, $category: String) {
    allContentfulBlogPost (
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        category: {
          eq: $category
        }
      }
    ) {
      edges {
        node {
          ...BlogPostMeta
          id
          content {
            json
          }
        }
      }
    }
  }
`;

const BlogListTemplate = ({
  data: {
    allContentfulBlogPost: data,
  },
  pageContext: {
    numPages,
    currentPage,
    category,
    categories,
  },
  ...props
}) => {

  // WHY: Format the posts into a usable array
  const posts = data.edges.map(({ node: post }) => post);

  const PaginationLink = ({ page, title, ...props }) => (
    page ?
    <SmartLink {...props} link={createBlogListUrl(page, category)} title={`Jump to ${title} page`}>{title}</SmartLink> :
    <span {...props} css={css` opacity: 0.5; `}>{title}</span>
  );

  const seoTitle = `ServiceLink & EXOS news, industry insights and press${category ? ` | ${category}` : ``}${currentPage > 1 ? ` | Page ${currentPage}` : ``}`;

  let pageTitle = category || `All blog posts`;
  if (currentPage > 1) {
    pageTitle += ` - page ${currentPage}`;
  }

  const otherCategories = categories.filter(val => val !== category);
  if (category) otherCategories.unshift(``);

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description="ServiceLink and EXOS' B2B mortgage insight hub, company news and press center."
      />

      <ResponsiveContainer width="thin" spacingTop="half">

        <div css={css`
          ${createSpacingUnitMediaQueries({
            'margin-bottom': `calc(SPACING_UNIT / 2)`,
          })}
        `}>
          <Heading level="h2" spacingBottom="half" spacingTop="none">
            {pageTitle}
          </Heading>

          <div css={css`display: flex; align-items: center;`}>
            <div>
              {category ?
                "Other categories:"
              :
                "Categories:"
              }
            </div>
            <ul css={css`
              display: flex;
              flex-wrap: wrap;
            `}>
              {otherCategories.map(category => (
                <li key={category} css={css`display: flex`}>
                  <SmartLink
                    link={createBlogListUrl(1, category)}
                    title={`See all posts${category ? ` in ${category}` : ``}`}
                    css={css`
                      margin-left: 1.7em;
                      padding: 0.5em;

                      :hover,
                      :focus {
                        text-decoration: underline;
                      }
                    `}
                  >
                    <Strapline css={css`margin: 0`}>
                      {category || `All`}
                    </Strapline>
                  </SmartLink>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <section>
          {posts.map(post => (
            <BlogItem key={post.id} {...post} layout="horizontal-small" css={css`
              ${createSpacingUnitMediaQueries({
                'margin-bottom': `calc(SPACING_UNIT / 2)`,
              }, {
                excludeBreakpoints: [`small`, `medium`, `large`],
              })}

              ${createSpacingUnitMediaQueries({
                'margin-bottom': `calc(SPACING_UNIT / 4)`,
              }, {
                excludeBreakpoints: [`tiny`],
              })}
            `} />)
          )}
        </section>

        {/* Pagination */}
        <section css={css`
          ${responsiveFontSizes(DEFAULT_FONT_SIZES_MAP.regular[0], DEFAULT_FONT_SIZES_MAP.regular[1])};

          ${createSpacingUnitMediaQueries({
            'margin-top': `calc(SPACING_UNIT / 2)`,
          })}

          display: flex;
          justify-content: center;

          a, span {
            display: block;
            padding: 0.5em;
          }

          > :first-child,
          > :last-child {
            // WHY: Balances the width of "Next" and "Previous" to keep it centered
            min-width: 5em;
          }

          a:hover {
            text-decoration: underline;
          }
        `}>
          <PaginationLink page={currentPage - 1} title="Previous" css={css`
            text-align: right;
          `} />
          <ol css={css`
            display: flex;
          `}>
            {Array.from({ length: numPages }).map((_, pageNumber) => <li key={pageNumber}>
              <SmartLink link={createBlogListUrl(pageNumber + 1, category)} title={`Jump to Page ${pageNumber + 1}`} css={css`
                ${pageNumber + 1 === currentPage && `
                  font-weight: 500;
                `}
              `}>{pageNumber + 1}</SmartLink>
            </li>)}
          </ol>
          <PaginationLink page={currentPage < numPages && currentPage + 1} title="Next" />
        </section>

      </ResponsiveContainer>
    </Layout>
  );
};

export default BlogListTemplate;
