import slugify from 'slugify';

import { blogUrl } from "../routes";

export const blogCategoryToSlug = category => slugify(category, {
  lower: true,
});

export const createBlogListUrl = (page = 1, category) => {
  let url = blogUrl;

  if (category && category !== `_all`) url += `${blogCategoryToSlug(category)}/`;
  if (page > 1) url += `${page}/`;

  return url;
}

export const createBlogPostUrl = slug => slug && `${blogUrl}${slug}/`;

export const createBlogExcerpt = ({ json } = {}, trim) => {
  if (!json) return ``;

  if (trim === true) trim = 200;

  const {
    content = [],
  } = json;

  const [firstParagraph] = content.filter(({ nodeType }) => nodeType === `paragraph`);

  if (!firstParagraph) return ``;

  const processString = test => test.reduce((acc, k) => {

    if (k.nodeType === `text`) {
      acc += k.value;
    } else {
      acc += processString(k.content)
    }

    return acc;
  }, ``);

  const string = processString(firstParagraph.content);

  return string.length > trim - 1 ? `${string.substr(0, trim - 1)}…` : string;
};
